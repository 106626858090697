
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { mapGetters } from "vuex";

    @Component({
        computed: {
            ...mapGetters({
                getLocale: "app/getLocale",
            }),        
        }
    })
    export default class SearchItem extends Vue {
        text: string = ""; 

        @Prop() placeHolder!: string;
        @Prop() buttonText!: string;
        @Prop() loadingSearch!: Boolean;

        async filterKiosk() {

            this.$emit("click", this.text);

        }
    }
    